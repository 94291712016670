import { Box, Button, Grid, Link, Typography } from "@mui/material"
import Routes from "@roger-build/common/dist/utils/routes"
import FullPageStatus from "frontend/components/FullPageStatus"
import GoogleIcon from "frontend/components/icons/GoogleIcon"
import MicrosoftIcon from "frontend/components/icons/MicrosoftIcon"
import RogerFullIcon from "frontend/components/icons/RogerFullIcon"
import { RogerSmallIcon } from "frontend/components/icons/RogerSmallIcon"
import CurvedLineDown from "frontend/components/icons/landing-icons/CurvedLineDown"
import DiagonalLine from "frontend/components/icons/landing-icons/DiagonalLine"
import VerticalLine from "frontend/components/icons/landing-icons/VerticalLine"
import theme from "frontend/theme/theme"
import type { NextPage } from "next"
import { getProviders, signIn } from "next-auth/react"
import Head from "next/head"
import { useRouter } from "next/router"
import React from "react"
import { useMediaQuery } from "react-responsive"
import useLoginGuard from "../frontend/hooks/useLoginGuard"

const OAUTH_LINK_ERROR = "OAuthAccountNotLinked"

const Home: NextPage = () => {
  const { status } = useLoginGuard({ guardLoggedOut: true })
  const [providers, setProviders] = React.useState<any>()
  const isMobile = useMediaQuery({ query: `(max-width: 760px)` })

  React.useEffect(() => {
    const getProvidersData = async () => {
      const providersData = await getProviders()
      setProviders(providersData)
      return
    }
    getProvidersData()
  }, [])
  const router = useRouter()
  const { query } = router

  if (status === "loading" || status === "authenticated") {
    return (
      <Box
        sx={{
          height: "100vh",
          display: "flex",
          flexDirection: "column",
        }}
        className="bg-rogerCharcoalDark"
      >
        <FullPageStatus dark />
      </Box>
    )
  }
  if (isMobile) {
    return (
      <Box sx={{ backgroundColor: "rogerPrimary.main", height: "100vh" }}>
        <Head>
          <title>Roger</title>
        </Head>
        {query.error && query.error === OAUTH_LINK_ERROR && (
          <Box>
            <Typography color="error" variant="body2">
              Error linking account
            </Typography>
          </Box>
        )}
        <Grid
          container
          className="bg-sand relative flex-col items-center justify-center w-screen h-screen overflow-hidden"
        >
          <VerticalLine className="left-10 absolute h-full" />
          <DiagonalLine
            className="left-0 top-[67%] absolute"
            style={{ width: 813, height: 473 }}
          />
          <CurvedLineDown
            className="right-[-48px] absolute top-[-24px]"
            style={{ width: 208, height: 328 }}
          />

          <Typography
            className="text-3xl"
            style={{
              letterSpacing: 4,
              fontFamily: "MarcellusRegular",
            }}
          >
            Welcome to
          </Typography>

          <RogerFullIcon
            className="m-6 text-primary mt-6 mb-[8px]"
            style={{ width: 290, height: 90 }}
          />

          <Typography
            className="text-rogerCharcoalDark mb-10 text-base font-bold"
            style={{
              fontFamily: "Outfit",
              lineHeight: "unset",
            }}
          >
            MAKING MANAGERS EXCELLENT
          </Typography>

          <Button
            startIcon={<MicrosoftIcon className="mb-1 mr-1" />}
            color="sand"
            className="w-[200px] h-[40px] mt-4 bg-white text-md rounded-none shadow-sm"
            style={{ boxShadow: "unset" }}
            onClick={() => {
              if (providers?.["azure-ad"]) {
                signIn(providers?.["azure-ad"].id)
              }
            }}
          >
            Sign in with Microsoft
          </Button>
          <Button
            startIcon={<GoogleIcon className="-ml-3" />}
            color="sand"
            className="w-[200px] h-[40px] mt-6 bg-white text-md rounded-none shadow-sm"
            // style={{ boxShadow: "unset" }}
            onClick={() => {
              if (providers?.["google"]) {
                signIn(providers?.["google"].id)
              }
            }}
          >
            Sign in with Google
          </Button>
          <div className="grow absolute gap-8 bottom-3 flex items-end justify-end pb-4">
            <Link
              href="https://tryroger.com/terms-of-service"
              target="_blank"
              className="text-tertiary cursor-pointer"
              style={{ textDecorationColor: theme.palette.tertiary.main }}
            >
              Terms of Service
            </Link>
            <Link
              href="https://tryroger.com/privacy-policy"
              target="_blank"
              className="text-tertiary cursor-pointer"
              style={{ textDecorationColor: theme.palette.tertiary.main }}
            >
              Privacy Policy
            </Link>
            <Link
              href={Routes.policyStatement}
              className="text-tertiary mr-4 cursor-pointer"
              style={{ textDecorationColor: theme.palette.tertiary.main }}
            >
              Limited Use Policy Statement
            </Link>
          </div>
        </Grid>
      </Box>
    )
  }

  return (
    <Box sx={{ height: "100vh" }} className="bg-rogerCharcoalDark">
      <Box className="flex h-full overflow-hidden">
        <Head>
          <title>Roger</title>
        </Head>
        <Grid container className="flex-col w-[50%] overflow-visible h-fit">
          <div className="flex flex-row p-4 items-center">
            <RogerSmallIcon
              className="m-4 text-white"
              style={{ width: 65, height: 48 }}
            />
            <Typography
              className="text-white"
              style={{
                fontSize: 36,
                fontFamily: "MarcellusRegular",
                maxWidth: "60%",
              }}
            >
              Making managers excellent.
            </Typography>
          </div>
          <img
            src="/landing_page_inbox.png"
            style={{ height: "80vh" }}
            className="mt-12 ml-4"
          />
        </Grid>
        <Grid
          container
          className="flex-col w-[50%] h-full overflow-hidden relative items-center"
          style={{
            boxShadow:
              "0px 4px 5px -3px rgba(30, 38, 57, 0.2), 0px 3px 10px 1px rgba(30, 38, 57, 0.14), 0px 2px 14px 2px rgba(30, 38, 57, 0.12)",
            borderRadius: "40px 0px 0px 40px",
            background:
              "linear-gradient(180deg, #BFD8BF 21.88%, #298FD6 99.99%, #E6E7DD 100%)",
          }}
        >
          <VerticalLine className="left-24 absolute h-full" />
          <DiagonalLine
            className="left-0 top-[60%] absolute"
            style={{ width: 813, height: 473 }}
          />
          <CurvedLineDown
            className="right-[-48px] absolute top-[-24px]"
            style={{ width: 208, height: 328 }}
          />
          <Typography
            className="text-rogerCharcoalDark mt-52 font-medium"
            style={{
              fontSize: 32,
              fontFamily: "Outfit",
              maxWidth: "80%",
            }}
          >
            Welcome to Roger.
          </Typography>
          <Button
            startIcon={<MicrosoftIcon className="mb-1 mr-1" />}
            color="sand"
            className="w-[200px] h-[40px] mt-4 bg-white text-md rounded-none shadow-sm"
            style={{ boxShadow: "unset" }}
            onClick={() => {
              if (providers?.["azure-ad"]) {
                signIn(providers?.["azure-ad"].id)
              }
            }}
          >
            Sign in with Microsoft
          </Button>
          <Button
            startIcon={<GoogleIcon className="-ml-3" />}
            color="sand"
            className="w-[200px] h-[40px] mt-4 bg-white text-md rounded-none shadow-sm"
            style={{ boxShadow: "unset" }}
            onClick={() => {
              if (providers?.["google"]) {
                signIn(providers?.["google"].id)
              }
            }}
          >
            Sign in with Google
          </Button>
          <div className="grow relative flex items-end justify-end pb-4 w-full">
            <Link
              href="https://tryroger.com/terms-of-service"
              target="_blank"
              className="text-secondary mr-2 cursor-pointer text-xs"
              style={{ textDecorationColor: theme.palette.tertiary.main }}
            >
              Terms of Service
            </Link>
            <Link
              href="https://tryroger.com/privacy-policy"
              target="_blank"
              className="text-secondary mr-2 cursor-pointer text-xs"
              style={{ textDecorationColor: theme.palette.tertiary.main }}
            >
              Privacy Policy
            </Link>
            <Link
              href={Routes.policyStatement}
              className="text-secondary mr-2 cursor-pointer text-xs"
              style={{ textDecorationColor: theme.palette.tertiary.main }}
            >
              Limited Use Policy Statement
            </Link>
          </div>
        </Grid>
      </Box>
    </Box>
  )
}

export default Home
