import Routes from "@roger-build/common/dist/utils/routes"
import * as Sentry from "@sentry/nextjs"
import { fullStoryIdentify } from "frontend/utils/fullstory"
import { useSession } from "next-auth/react"
import Router from "next/router"
import React from "react"

export interface UseLoginGuardArgs {
  guardLoggedOut: boolean
}

const useLoginGuard = (args?: UseLoginGuardArgs) => {
  const session = useSession()
  const guardLoggedOut = args?.guardLoggedOut

  React.useEffect(() => {
    if (guardLoggedOut && session.data) {
      const user = session.data.user
      if (user && user.email) {
        Sentry.setUser({ email: user.email, username: user.name || undefined })
        fullStoryIdentify({
          id: user.email,
          displayName: user.name || undefined,
          email: user.email,
        })
      }
      Router.push(Routes.workhub)
    } else if (
      !guardLoggedOut &&
      session.status !== "loading" &&
      !session.data
    ) {
      Router.push(Routes.home)
    }
  }, [session, guardLoggedOut])

  return session
}

export default useLoginGuard
