import { SvgIcon, SvgIconProps } from "@mui/material"

const CurvedLineDown = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props} viewBox="0 0 153 324">
      <svg
        width="153"
        height="324"
        viewBox="0 0 153 324"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M209 323C94.1402 323 1 175.915 1 -5.46875"
          stroke="#1E1E1E"
          strokeWidth="0.575"
          strokeMiterlimit="10"
          strokeDasharray="1.5 1.5"
        />
      </svg>
    </SvgIcon>
  )
}

export default CurvedLineDown
