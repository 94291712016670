import Box, { BoxProps } from "@mui/material/Box"
import { styled } from "@mui/material/styles"

const FullHeightBox = styled(Box)<BoxProps>({
  display: "flex",
  flexGrow: 1,
  height: "100vh",
})

export default FullHeightBox
