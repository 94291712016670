const FS = () =>
  typeof window !== "undefined" ? (window as any).FS : undefined

const envReleaseStage = "unknown"

export interface IdentifyArgs {
  id: string
  displayName?: string
  email: string
}

export const fullStoryIdentify = ({ id, displayName, email }: IdentifyArgs) => {
  FS().identify(`${envReleaseStage}-${id}`, {
    displayName,
    email,
  })
}

export const fullStoryAnonymize = () => FS()?.anonymize()

export interface EventArgs {
  name: string
  properties: { [key: string]: string | undefined }
}

export const fullStoryEvent = ({ name, properties }: EventArgs) => {
  FS().event(name, properties)
}
