import { Button } from "@mui/material"
import { useRouter } from "next/router"
import { useSnackbar } from "notistack"
import React from "react"

export function useNetworkMonitor() {
  const [isOffline, setIsOffline] = React.useState(false)

  const { enqueueSnackbar, closeSnackbar } = useSnackbar()
  const router = useRouter()

  React.useEffect(() => {
    function handleNetworkChange() {
      setIsOffline(!navigator.onLine)
      if (!navigator.onLine) {
        enqueueSnackbar("You are offline, please reload to reconnect.", {
          persist: true,
          style: { marginLeft: 80 },
          disableWindowBlurListener: true,
          action: (key: any) => (
            <Button
              size="small"
              variant="text"
              sx={(theme) => ({ color: theme.palette.blue.bright })}
              onClick={() => {
                router.reload()
                closeSnackbar(key)
              }}
            >
              Reload
            </Button>
          ),
        })
      }
    }

    window.addEventListener("online", handleNetworkChange)
    window.addEventListener("offline", handleNetworkChange)

    return () => {
      window.removeEventListener("online", handleNetworkChange)
      window.removeEventListener("offline", handleNetworkChange)
    }
  }, [])

  return isOffline
}
