import { SvgIcon, SvgIconProps } from "@mui/material"

export const RogerSmallIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props} viewBox="0 0 66 50">
      <svg
        width="66"
        height="50"
        viewBox="0 0 66 50"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M36.6111 15.2222C36.6111 6.72222 30.5556 1 21.1111 1H0V49H8.83333V23.3333V11.0556C8.83333 9.72222 9.88889 8.66667 11.2222 8.66667H19.2778C24.5 8.66667 27.2222 11.3889 27.2222 15.8889C27.2222 20.5556 24.2222 23.4444 18.2222 23.3889H16.8333C13.5 23.3889 11.5556 27.1667 13.5556 29.8889L27.6667 49.1111H37.6667L22.9444 29.1111C32.3333 28.7778 36.6111 23.2222 36.6111 15.2222Z"
          fill="white"
        />
        <path
          d="M47.6667 0.944336H37.6667L53 21.7777C54.4444 23.7221 54.4444 26.3888 53 28.3332L37.8333 48.9999H47.7778L65.2778 24.9999L47.6667 0.944336Z"
          fill="white"
        />
      </svg>
    </SvgIcon>
  )
}
