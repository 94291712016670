import { SvgIcon, SvgIconProps } from "@mui/material"

const DiagonalLine = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props} viewBox="0 0 813 472">
      <svg
        width="813"
        height="472"
        viewBox="0 0 813 472"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g opacity="0.9">
          <line
            x1="0.138251"
            y1="41.8555"
            x2="530.138"
            y2="548.855"
            stroke="#1E1E1E"
            strokeWidth="0.4"
          />
        </g>
      </svg>
    </SvgIcon>
  )
}

export default DiagonalLine
