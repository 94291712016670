import RefreshOutlined from "@mui/icons-material/RefreshOutlined"
import { Button } from "@mui/material"
import CircularProgress from "@mui/material/CircularProgress"
import FullHeightBox from "frontend/components/FullHeightBox"
import TrpcErrorDisplay from "frontend/components/TrpcErrorDisplay"
import { useNetworkMonitor } from "frontend/hooks/useNetworkMonitor"
import { TError } from "frontend/trpc/types"
import { useRouter } from "next/router"
import React from "react"

export interface FullPageStatusProps {
  error?: TError | null | (TError | null)[]
  dark?: boolean
}

const FullPageStatus = ({ error }: FullPageStatusProps) => {
  const errorActual = React.useMemo(() => {
    if (Array.isArray(error)) {
      return error.find((err) => err != null)
    } else {
      return error
    }
  }, [error])

  const [elapsedTime, setElapsedTime] = React.useState(0)

  React.useEffect(() => {
    const intervalId = setInterval(() => {
      setElapsedTime((prevTime) => prevTime + 1)
    }, 1000)
    return () => clearInterval(intervalId)
  }, [])

  const offline = useNetworkMonitor()
  const router = useRouter()

  return (
    <FullHeightBox
      sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}
    >
      {errorActual ? (
        <TrpcErrorDisplay error={errorActual} />
      ) : elapsedTime > 15 || offline ? (
        <div className="flex flex-col items-center justify-center gap-4 text-lg -mt-[20vh]">
          You are disconnected from Roger. please try reloading
          <Button
            className=" text-white rounded-full text-lg gap-1 px-4"
            onClick={() => router.reload()}
          >
            <RefreshOutlined /> Reload
          </Button>
        </div>
      ) : (
        <CircularProgress />
      )}
    </FullHeightBox>
  )
}

export default FullPageStatus
