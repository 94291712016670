import { Typography } from "@mui/material"
import { TError } from "frontend/trpc/types"
import React from "react"

export interface MutationErrorDisplayProps {
  error: TError | null | (TError | null)[]
}

const getErrorMessage = (error: TError) => {
  switch (error.message) {
    case "UNAUTHORIZED":
      return "Server responded unauthorized, try logging in again or contact us."
    default:
      return "An issue was encountered, please try again."
  }
}

// Todo auth errors
const TrpcErrorDisplay = ({ error }: MutationErrorDisplayProps) => {
  const errorActual = React.useMemo(() => {
    if (Array.isArray(error)) {
      return error.find((err) => err != null)
    } else {
      return error
    }
  }, [error])

  if (errorActual == null) {
    return null
  }

  return (
    <Typography variant="body2" color="error">
      {getErrorMessage(errorActual)}
    </Typography>
  )
}

export default TrpcErrorDisplay
