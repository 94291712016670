import { SvgIcon, SvgIconProps } from "@mui/material"

const RogerFullIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props} viewBox="0 0 200 58">
      <svg
        width="200"
        height="58"
        viewBox="0 0 200 58"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M37.7595 21.9505C37.7595 15.788 33.4395 11.6797 26.6395 11.6797H11.5195V46.3186H17.8795V27.7908V19.2922C17.8795 18.1241 18.8395 17.1575 19.9995 17.1575H25.3595C29.1195 17.1575 31.0795 19.1311 31.0795 22.3533C31.0795 25.7366 28.9195 27.7908 24.6395 27.7505H23.6795C21.2795 27.7505 19.9195 30.4894 21.3195 32.4227L31.3995 46.3186H38.5995L28.0395 31.8991C34.7195 31.6977 37.7595 27.67 37.7595 21.9505Z"
          fill="currentColor"
        />
        <path
          d="M118.199 46.3186H140.479V40.6394H126.639C125.479 40.6394 124.519 39.6727 124.519 38.5047V33.4297C124.519 32.2616 125.479 31.295 126.639 31.295H138.839V26.0991H126.639C125.479 26.0991 124.519 25.1325 124.519 23.9644V19.3727C124.519 18.2047 125.479 17.238 126.639 17.238H140.479V11.6797H118.199V46.3186Z"
          fill="currentColor"
        />
        <path
          d="M171.32 21.9505C171.32 15.788 167 11.6797 160.2 11.6797H145.08V46.3186H151.44V27.7908V18.8894C151.44 17.963 152.2 17.1575 153.16 17.1575H158.92C162.68 17.1575 164.64 19.1311 164.64 22.3533C164.64 25.7366 162.48 27.7908 158.2 27.7505H157.2C154.8 27.7505 153.44 30.4894 154.84 32.4227L164.96 46.2783H172.16L161.64 31.8589C168.28 31.6977 171.32 27.67 171.32 21.9505Z"
          fill="currentColor"
        />
        <path
          d="M179.239 11.6406H172.039L182.999 26.6642C184.039 28.074 184.039 29.967 182.999 31.3767L172.159 46.3198H179.279L191.879 28.9601L179.239 11.6406Z"
          fill="currentColor"
        />
        <path
          d="M94.2393 31.2929H105.479C106.839 31.2929 107.839 32.5818 107.519 33.9109C106.439 38.3415 102.639 41.3623 97.6793 41.3623C90.9193 41.3623 85.9993 36.1665 85.9993 29.0776C85.9993 21.9484 91.1593 16.7526 98.1993 16.7526C101.319 16.7526 105.239 18.0818 107.439 19.8943L110.399 14.779C107.679 12.4429 102.319 10.7109 97.7993 10.7109C87.1193 10.7109 79.2793 18.4845 79.2793 29.0776C79.2793 39.5901 86.9593 47.3234 97.4793 47.3234C107.079 47.3234 114.079 39.872 113.999 29.722V26.097H94.1593V31.2929H94.2393Z"
          fill="currentColor"
        />
        <path
          d="M58.2395 10.672C47.9595 10.672 40.4395 18.4054 40.4395 28.9581C40.4395 39.5512 47.9595 47.2845 58.2395 47.2845C68.5595 47.2845 76.1195 39.5512 76.1195 28.9581C76.1195 18.3651 68.5595 10.6318 58.2395 10.672ZM58.2395 41.3637C51.8395 41.3637 47.1195 36.1679 47.1195 29.0387C47.1195 21.9095 51.8395 16.6331 58.2395 16.6331C64.7195 16.6331 69.4795 21.8693 69.4795 29.0387C69.4795 36.2081 64.7195 41.3637 58.2395 41.3637Z"
          fill="currentColor"
        />
      </svg>
    </SvgIcon>
  )
}

export default RogerFullIcon
