import { SvgIcon, SvgIconProps } from "@mui/material"

const VerticalLine = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props} viewBox="0 0 3 973">
      <svg
        width="3"
        height="973"
        viewBox="0 0 3 973"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M1 982L2 4.06504e-05"
          stroke="#1E1E1E"
          strokeWidth="0.575"
          strokeMiterlimit="10"
          strokeDasharray="1.5 1.5"
        />
      </svg>
    </SvgIcon>
  )
}

export default VerticalLine
